body {
  font-family: 'Untitled Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('../public/bg.jpg');
  background-size: cover;       */
}

html,
body,
#root {
  padding: 0px !important;
  margin: 0px !important;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.twitter-follow-button {
  display: block;
}

#root {
  background: radial-gradient(37.74% 81.78% at 50% 26.56%, rgba(148, 101, 255, 0.08) 0%, rgba(3, 0, 20, 0) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hero-button {
  font-weight: 500;
  font-size: 14px;
  appearance: none;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  max-width: 100%;
  flex-shrink: 0;
  color: inherit;
  height: 36px;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
  gap: 6px;
  font: inherit inherit inherit inherit inherit inherit inherit inherit inherit inherit inherit inherit inherit;
  transition: border 120ms ease 0s, background-color, color, box-shadow, opacity;
  border-radius: 9999px;
  padding: 0px 15px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background: linear-gradient(92.88deg, rgb(69, 94, 181) 9.16%, rgb(86, 67, 204) 43.89%, rgb(103, 63, 215) 64.72%);
  animation: 1200ms ease 800ms 1 normal backwards running gisjuz;
  text-decoration: none;
  outline: none;
  vertical-align: baseline;
}

.hero-button svg {
  margin-top: 5px;
}
